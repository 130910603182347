import React from "react";
import Img from "react-cloudinary-lazy-image";
import tw from "twin.macro";

const Header = () => {
    return (
        <>
            <div css={[tw`fixed top-10 z-50 w-full`]}>
                <div css={[tw`w-64 sm:w-72 md:w-96 mx-auto`]}>
                    <Img
                        css={[tw`duration-300 mx-auto text-white`]}
                        cloudName="ajmontroig"
                        imageName={`web/logos/logo-text-icon_oay9u8`}
                        fluid={{
                            maxWidth: 512,
                        }}
                        alt={"BDTMM"}
                    />
                </div>
            </div>
        </>
    );
};

export default Header;
