import React from "react";
import { useIntl, changeLocale, Link } from "gatsby-plugin-intl";
import Img from "react-cloudinary-lazy-image";
import tw from "twin.macro";

const Footer = () => {
    const intl = useIntl();

    return (
        <>
            <div
                css={[
                    tw`z-50 fixed bottom-0 w-full bg-gray-300 py-5 px-10 bg-opacity-10 shadow`,
                ]}
            >
                <div
                    css={[
                        tw`lg:flex items-center justify-between font-montserrat text-xs font-semibold uppercase text-white opacity-80`,
                    ]}
                >
                    <div css={[tw`text-center lg:text-left`]}>
                        Copyright &copy; {new Date().getFullYear()} -{" "}
                        {intl.formatMessage({ id: "3" })}
                        <button css={[tw`ml-5 duration-300 mr-2`, intl.locale === "es" ? tw`border-b` : null]} onClick={() => { changeLocale("es"); }}>ES</button>
                        |
                        <button css={[tw`duration-300 mx-2`, intl.locale === "ca" ? tw`border-b` : null]} onClick={() => { changeLocale("ca"); }}>CA</button>
                        |
                        <Link css={[tw`duration-300 hover:border-b ml-2`]} to="/gdpr/">{intl.formatMessage({ id: "8" })}</Link>
                    </div>
                    <div
                        css={[
                            tw`hidden lg:block text-center lg:text-right lg:flex lg:items-center`,
                        ]}
                    >
                        {intl.formatMessage({ id: "1" })}
                        <div css={[tw`inline w-5 h-5 ml-2`]}>
                            <Img
                                css={[
                                    tw`duration-300 mx-auto text-white w-7 h-7`,
                                ]}
                                cloudName="ajmontroig"
                                imageName={`web/logos/logo-rounded_vdme8m`}
                                fluid={{
                                    maxWidth: 80,
                                }}
                                alt={"LOGO"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
