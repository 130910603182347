import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Img from "react-cloudinary-lazy-image";
import tw from "twin.macro";
import { useIntl } from "gatsby-plugin-intl";

const images = [
    "bg1_yvyryl",
    "bg2_ijitca",
    "bg3_gxnbst",
    "bg4_uh5dwu",
    "bg5_mg9dl5",
    "bg6_fvsahc",
    "bg7_d8wdz8",
    "bg9_rlhwix",
    "bg10_rnmdtc",
    "bg11_ycu4rj",
    "bg12_jsistc",
    "bg13_xezo6w",
    "bg14_vjbgw3",
    "bg15_hhhkiy",
];

const random = Math.floor(Math.random() * images.length);

const Layout = ({ children }) => {
    const image = images[random];
    const intl = useIntl();

    return (
        <>
            <Header />

            {/** Background */}
            <div
                css={[
                    tw`h-screen w-screen bg-gray-50 text-white text-center fixed z-0`,
                ]}
            >
                <Img
                    css={[
                        tw`h-full w-full object-cover text-white`,
                    ]}
                    cloudName="moybu"
                    imageName={`bdtmm/dashboard/bgs/${image}`}
                    fluid={{
                        maxWidth: 1600,
                    }}
                    urlParams={
                        "g_face,c_lfill,e_art:fes,e_blur:50,b_black,o_75"
                    }
                    alt={intl.formatMessage({ id: "4" })}
                />
            </div>

            {/** Main */}
            <div css={[tw`absolute h-full w-full flex`]}>
                <main css={[tw`mx-auto my-auto`]}>
                    {children}
                </main>
            </div>


            <Footer />
        </>
    );
};

export default Layout;
